<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import AddressAutoComplete from '@/components/address-auto-complete-input'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import {
  required,
} from "vuelidate/lib/validators";

import { getPMApi } from '@/api/pm'




/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",

    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations: {

    property: {
      agent_name: { required },
      start_date: { required },
      rent: { required },

      owner_name: { required },
      l_payment: { required },

      monthly_manager_commission: {required,  greaterThanZero(value) {
          return value > 0;
        },}
     
    },


    rta_code: { required },
    addr_city: { required },
    tenants: {
      $each: {
        name: { required },
        payment_type: { required },
        rent: { required },
      }
    },

    landlords: {
      $each: {
        name: { required },
        payment_type: { required },
      }
    }
  },


  methods: {

    formSubmit() {

      console.log(this.property)
      this.landlords.map(e => e.landlord_name = e.name)
      this.$v.$touch();
      console.log(this.$v)
      if (this.$v.$invalid == false) {
        let pm = {
          agent_id: this.property.agent_id,
          apt: this.addr_apt,
          address: this.addr_address,
          city: this.addr_city,
          province: this.addr_province,
          post_code: this.addr_postal_code,

          owner_name: this.property.owner_name,
          l_payment: this.property.l_payment,
          l_institution: this.property.l_institution,
          l_transfer: this.property.l_transfer,
          l_account_number: this.property.l_account_number,


          monthly_manager_commission: this.property.monthly_manager_commission,
          monthly_manger_pay_way: this.comm_type_rate == true ? 0 : 1,
          is_month_by_month: this.property.is_month_by_month ? 1 : 0,


          rent: this.property.rent,
          rta_code: this.rta_code,
          bto_code: this.property.bto_code,

          term: {
            start_date: this.property.start_date,
            end_date: this.property.end_date
          },
          landlords: this.landlords,
          tenants: this.tenants,
        }
        getPMApi().setup_new_rta(pm).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Property is Setup");
            this.$router.push({ name: 'pm-property-list' })
          } else {
            this.$alertify.error("Setup Property failed:" + res.errCode);
          }
        })
      }

    },


    onSelectedAgent(agent) {
      this.property.agent_id = agent.agent.id
      this.property.agent_name = agent.agent.first_name + ' ' + agent.agent.last_name

      getPMApi().get_rta_code({ agent_id: agent.agent.id }).then(res => {
        if (res.errCode == 0) {
          this.rta_code = res.data
        }
      })
    },
    onLandlordAddressSelected(evt) {
      this.addr_address = evt.street
      this.addr_city = evt.city
      this.addr_province = evt.province
    },

    onAddLandlord() {
      this.landlords.push({
        name: '',
        landlord_name: '',
        payment_type: 'CHEQUE',
        owner_ship: '100',

        institution: '',
        transit: '',
        account: '',
        is_corporatuin: 0,
        owner_t_code: this.temp_owner_code++

      })
    },

    onAddTenant() {
      this.tenants.push({
        name: '',
        email: '',
        phone: '',
        payment_type: 'CHEQUE',
        institution: '',
        transit: '',
        account: '',
        rent: '',

      })
    },



    onRemoveTenant(idx) {
      this.tenants.splice(idx, 1)
    },
    onRemoveLandlord(idx) {
      this.landlords.splice(idx, 1)
    },

    onSelectBTO(evt) {
      this.property.owner_name = evt.name
      this.property.l_payment = evt.payment_type
      this.property.bto_code = evt.owner_t_code
      if (evt.payment_type == 'EFT') {
        this.property.l_institution = evt.institution
        this.property.l_transfer = evt.transit
        this.property.l_account_number = evt.account

      }
      console.log(evt, this.property)
    }

  },

  data() {
    return {
      title: "Setup New RTA",

      items: [
        {
          text: "RTA List",
          href: "/pm/property_list",
        },
        {
          text: "Setup New RTA",
          active: true,
        },
      ],

      rta_code: '',
      addr_apt: '',
      addr_city: '',
      addr_province: '',
      addr_postal_code: '',
      addr_address: '',

      tenants: [],

      property: {
        agent_id: '',
        rent: '',
        start_date: '',
        end_date: '',
        agent_name: '',
        is_month_by_month: true,
        monthly_manager_commission: 0,
        monthly_manger_pay_way: 1,
        landloard: {
          name: ''
        },

        l_payment: '',
        l_institution: '',
        l_transfer: '',
        l_account_number: '',
        bto_code: '',
        owner_name: '',

        address: '',
      },

      landlords: [],


      comm_type_rate: true,
      tenant_name: '',
      tenant_payment_type: 'PAD',
      t_institution: '',
      t_transit: '',
      t_account: '',
      property_address: '',
      searched_property_address: '',
      properties_data: [],
      pma_list_data: [],

      temp_owner_code: 20001,
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    AgentAutoComplete,
    AddressAutoComplete,
    VueBootstrapTypeahead,

  },

  created() {
    this.$validator.extend(
      'greaterThanZero',{
      getMessage: field =>  field + ' needs to be > zero.',
      validate: (value) => {
        // value must be > zero
        if (value > 0 ) return true;
        return false;
      }
    });
  },
  mounted() {

    this.onAddTenant()
    this.onAddLandlord()
  },

  watch: {

    searched_property_address(new_search_name) {
      this.queryProperty(new_search_name)
    },


  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">


              <div class="row">
                <label class="form-label">Basic Info</label>
              </div>
              <hr class="mt-1">

              <div>
                <div class="row">
                  <div class="col-lg-3">
                    <div class="mb-3">
                      <label class="form-label" for="gen-info-name-input">Property Manager</label>
                      <AgentAutoComplete v-model="property.agent_name" @onSelected="onSelectedAgent" autocomplete="off"
                        :init_data="{ style_class: { 'form-control': 'form-control', 'is-invalid': $v.property.agent_name.$error }, agent_name: property.agent_name, agent_id: property.agent_id }" />
                      <div v-if="$v.property.agent_name.$error" class="invalid-feedback">
                        <span v-if="!$v.property.agent_name.required">This value is required.</span>
                      </div>

                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="mb-3">
                      <label class="form-label" for="gen-info-name-input">RTA Code</label>
                      <input type="text" class="form-control" :class="{ 'is-invalid': $v.rta_code.$error }"
                        v-model="rta_code" />
                      <div v-if="$v.rta_code.$error" class="invalid-feedback">
                        <span v-if="!$v.rta_code.required">This value is required.</span>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-lg-1">
                    <div class="mb-3">
                      <label class="form-label">Apt</label>
                      <input type="text" class="form-control" v-model="addr_apt" />
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="mb-3">
                      <label class="form-label">Address</label>
                      <AddressAutoComplete v-model="addr_address" :fill_full="false"
                        @onSelected="onLandlordAddressSelected($event)" autocomplete="off" />
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label">City</label>
                      <input type="text" class="form-control" v-model="addr_city"
                        :class="{ 'is-invalid': $v.addr_city.$error }" />
                      <div v-if="$v.addr_city.$error" class="invalid-feedback">
                        <span v-if="!$v.addr_city.required">This value is required.</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label>Province</label>
                      <input type="text" class="form-control" v-model="addr_province" />
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label>Postal Code</label>
                      <input type="text" class="form-control" v-model="addr_postal_code" />
                    </div>
                  </div>


                </div>


              </div>

              <!-- end row-->

              <div class="row pt-3">
                <label class="form-label">Term Info</label>
              </div>
              <hr class="mt-1">


              <div class="row">
                <div class="col-lg-2">
                  <div class="mb-3 form-floating">

                    <input type="text" v-model="property.rent" class="form-control" placeholder="Enter Amount"
                      id="rent_amount" :class="{ 'is-invalid': $v.property.rent.$error }" />
                    <label class="form-label" for="rent_amount">Rent</label>
                    <div v-if="$v.property.rent.$error" class="invalid-feedback">
                      <span v-if="!$v.property.rent.required">This value is required.</span>
                    </div>

                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3 form-floating">

                    <flat-pickr v-model="property.start_date" placeholder="Select a date" class="form-control"
                      id="start_date" :class="{ 'is-invalid': $v.property.start_date.$error }"></flat-pickr>
                    <label class="form-label" for="start_date">Start Date</label>
                    <div v-if="$v.property.start_date.$error" class="invalid-feedback">
                      <span v-if="!$v.property.start_date.required">This value is required.</span>
                    </div>
                  </div>
                </div>


                <div class="col-lg-2">
                  <div class="mb-3 form-floating">

                    <input type="text" v-model="property.monthly_manager_commission" class="form-control"
                      id="commission" placeholder="Amount or Rate"  :class="{ 'is-invalid': $v.property.monthly_manager_commission.$error }" />
                    <label class="form-label" for="commission">Management Fee {{ comm_type_rate == true?'%':'' }}</label>
                    <div v-if="$v.property.monthly_manager_commission.$error" class="invalid-feedback">
                      <span v-if="!$v.property.monthly_manager_commission.required">This value is required</span>
                      <span v-if="!$v.property.monthly_manager_commission.greaterThanZero"> and Must greator 0</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="mb-3 form-floating">

                    <b-form-select v-model="comm_type_rate" class="form-select" id="comm_type"
                      :options="[{ value: true, text: 'Rent Ratio' }, { value: false, text: 'Fixed Amount' }]">
                    </b-form-select>
                    <label class="comm_type">By Portion</label>
                  </div>
                </div>

              </div>
              <!-- end row-->





              <!-- Tenant Block  -->
              <div class="row pt-3">
                <label class="form-label">Tenant Info</label>
              </div>
              <hr class="mt-1">


              <div class="row" v-for="(tenant, idx) in tenants" :key="'tenant_' + idx">

                <div class="row">
                  <div class="col-md-2">
                    <div class="md-3 d-flex align-items-start justify-content-md gap-3">
                      <label class="form-label">●</label> <label class="form-label">Tenant {{ (idx + 1) }}</label>

                      <div class="icon-demo-content-10">
                        <div class="col-xl-4 col-lg-4 col-sm-6" @click="onRemoveTenant(idx)">
                          <i class="uil-times font-size-6"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <div class="row">
                  <div class="col-md-2">
                    <div class="mb-3 form-floating">

                      <input type="text" class="form-control" v-model="tenant.name"
                        :class="{ 'is-invalid': $v.tenants.$each[idx].name.$error }" maxlength="50" />
                      <label for="contact-info-email-input">Tenant {{ idx + 1 }}:</label>
                      <div v-if="$v.tenants.$each[idx].name.$error" class="invalid-feedback">
                        <span v-if="!$v.tenants.$each[idx].name.required">This value is required.</span>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-2">
                    <div class="mb-3 form-floating">

                      <input type="text" v-model="tenant.email" class="form-control" placeholder="Enter Email"
                        id="tenant_email" />
                      <label for="tenant_email">Email </label>
                    </div>

                  </div>
                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <input type="text" v-model="tenant.phone" class="form-control" placeholder="Enter Number"
                        id="teaant_number" />
                      <label for="teaant_number">Phone </label>
                    </div>

                  </div>

                  <div class="col-md-2">
                    <div class="mb-3   form-floating">


                      <b-form-select v-model="tenant.payment_type" class="form-select" id="tenant_payment_type"
                        :class="{ 'is-invalid': $v.tenants.$each[idx].payment_type.$error }"
                        :options="[{ value: 'PAD', text: 'PAD' }, { value: 'CHEQUE', text: 'Cheque' }, { value: 'ETransfer', text: 'ETransfer' }]">

                      </b-form-select>
                      <label for="tenant_payment_type">PAD Payment</label>
                      <div v-if="$v.tenants.$each[idx].payment_type.$error" class="invalid-feedback">
                        <span v-if="!$v.tenants.$each[idx].payment_type.required">This value is required.</span>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 form-floating">

                      <input type="text" v-model="tenant.rent"
                        :class="{ 'is-invalid': $v.tenants.$each[idx].rent.$error }" class="form-control"
                        placeholder="Enter Rent" id="tenant_rent" />
                      <label for="tenant_rent">Rent </label>
                      <div v-if="$v.tenants.$each[idx].rent.$error" class="invalid-feedback">
                        <span v-if="!$v.tenants.$each[idx].rent.required">This value is required.</span>
                      </div>
                    </div>
                  </div>



                </div>
                <div class="row" v-if="tenant.payment_type == 'PAD'">
                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <input type="text" class="form-control" v-model="tenant.institution" placeholder="  Institution "
                        id="tenant_inst" />
                      <label for="tenant_inst" class="form-label">Institution</label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <input type="text" class="form-control" v-model="tenant.transit" placeholder="Transit Number"
                        id="tenant_transit" />
                      <label for="tenant_transit" class="form-label">Transit</label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <input type="text" class="form-control" v-model="tenant.account" placeholder=" Bank Account"
                        id="tenant_account" />
                      <label for="tenant_account" class="form-label">Account</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row-->

              <div class="row">
                <div class="mb-3">

                  <b-button variant="outline-primary" @click="onAddTenant">Add Tenant</b-button>

                </div>
              </div>
              <!-- end row -->





              <!-- Landlord Block  -->
              <div class="row pt-3">
                <label class="form-label">Landlord Info</label>
              </div>
              <hr class="mt-1">

              <div class="row" v-for="(landlord, idx) in landlords" :key="'landlord_' + idx">
                <div class="row">
                  <div class="col-md-2">
                    <div class="md-3 d-flex align-items-start justify-content-md gap-3">
                      <label class="form-label">●</label> <label class="form-label">Landlord {{ (idx + 1) }}</label>

                      <div class="icon-demo-content-10">
                        <div class="col-xl-4 col-lg-4 col-sm-6" @click="onRemoveLandlord(idx)">
                          <i class="uil-times font-size-6"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <div class="row">
                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <input type="text" class="form-control" v-model="landlord.name"
                        :class="{ 'is-invalid': $v.landlords.$each[idx].name.$error }" maxlength="50" id="name"
                        placeholder="name" />

                      <label class="form-label" for="name">Name</label>

                      <div v-if="$v.landlords.$each[idx].name.$error" class="invalid-feedback">
                        <span v-if="!$v.landlords.$each[idx].name.required">This value is required.</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3  form-floating">
                      <input type="text" class="form-control" v-model="landlord.email" id="email" placeholder="email" />
                      <label class="form-label" for="email">Email</label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <input type="text" class="form-control" v-model="landlord.phone" id="phone" placeholder="phone" />
                      <label class="form-label" for="phone"> Phone</label>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <b-form-select v-model="landlord.payment_type" class="form-select" id="payment_type"
                        placeholder="Select Payment Type"
                        :class="{ 'is-invalid': $v.landlords.$each[idx].payment_type.$error }"
                        :options="[{ value: 'EFT', text: 'EFT' }, { value: 'CHEQUE', text: 'Cheque' }]">

                      </b-form-select>
                      <label for="payment_type">Payment</label>
                      <div v-if="$v.landlords.$each[idx].payment_type.$error" class="invalid-feedback">
                        <span v-if="!$v.landlords.$each[idx].payment_type.required">This value is required.</span>
                      </div>

                    </div>

                  </div>

                  <div class="col-md-1">
                    <div class="mb-3  form-floating ">

                      <b-form-select v-model="landlord.landlord_is_non_resident" class="form-select" id="nr_flag"
                        placeholder="Select Owner Status"
                        :class="{ 'is-invalid': $v.landlords.$each[idx].payment_type.$error }"
                        :options="[{ value: '0', text: 'Yes' }, { value: '1', text: 'No' }]">

                      </b-form-select>


                      <label class="form-label" for="formrow-nr_flag">NR</label>

                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3  form-floating">
                      <input type="text" class="form-control" v-model="landlord.owner_ship" id="owner_ship"
                        placeholder="Owner Ship" />
                      <label class="form-label" for="owner_ship">Owner Portion(%)</label>
                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col-md-6">
                    <div class="mb-3  form-floating">

                      <input type="text" class="form-control" v-model="landlord.landlord_address" id="landlord_address"
                        placeholder="landlord_address" />
                      <label class="form-label" for="landlord_address">Address</label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <input type="text" class="form-control" v-model="landlord.email2" id="email2"
                        placeholder="email2" />
                      <label class="form-label" for="email2">Email2</label>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3  form-floating">

                      <input type="text" class="form-control" v-model="landlord.email3" id="email3"
                        placeholder="email3" />
                      <label class="form-label" for="email3">Email3</label>
                    </div>
                  </div>

                </div>


                <div class="row" v-if="landlord.payment_type == 'EFT'">
                  <div class="col-md-2">
                    <div class="mb-3 form-floating ">

                      <input type="text" class="form-control" v-model="landlord.institution" id="owner_institution"
                        placeholder="Enter Bank  Institution Number" />
                      <label for="owner_institution" class="form-label"> Institution</label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3 form-floating ">

                      <input type="text" class="form-control" v-model="landlord.transit" id="owner_transit"
                        placeholder="Enter Bank Transit Number" />
                      <label for="owner_transit" class="form-label"> Transit</label>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3 form-floating ">

                      <input type="text" class="form-control" v-model="landlord.account"
                        placeholder="Enter Bank Account" id="owner_account" />
                      <label for="owner_account" class="form-label"> Account</label>
                    </div>
                  </div>
                </div>

              </div>
              <!-- end row-->
              <div class="row">
                <div class="mb-3">
                  <b-button variant="outline-primary" @click="onAddLandlord">Add Landlord</b-button>
                </div>
              </div>
              <!-- end row -->



              <div class="row pt-3">
                <label class="form-label">BTO Info</label>
              </div>
              <hr class="mt-1">

              <div class="row">
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label class="form-label" for="gen-info-name-input">BTO Name</label>
                    <vue-bootstrap-typeahead v-model="property.owner_name" :remoteMatch="false" :minMatchingChars="1"
                      :data="landlords" :serializer="s => s.name" :foramterDisplay="s => s.name"
                      :styleClass="{ 'form-control': 'form-control', 'is-invalid': $v.property.owner_name.$error }"
                      @hit="onSelectBTO($event)" autocomplete="off" />

                    <div v-if="$v.property.owner_name.$error" class="invalid-feedback">
                      <span v-if="!$v.property.owner_name.required">This value is required.</span>
                    </div>

                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label class="form-label" for="gen-info-designation-input">Payment Type</label>
                    <b-form-select v-model="property.l_payment" class="form-control"
                      :class="{ 'is-invalid': $v.property.l_payment.$error }"
                      :options="[{ value: 'EFT', text: 'EFT' }, { value: 'CHEQUE', text: 'Cheque' }]">
                    </b-form-select>
                    <div v-if="$v.property.l_payment.$error" class="invalid-feedback">
                      <span v-if="!$v.property.l_payment.required">This value is required.</span>
                    </div>

                  </div>
                </div>

                <div class="col-md-2" v-if="property.l_payment == 'EFT'">
                  <div class="mb-md-0">
                    <label for="contact-info-website-input" class="form-label"> Institution</label>
                    <input type="text" class="form-control" v-model="property.l_institution"
                      placeholder="Enter Bank  Institution Number" />
                  </div>
                </div>
                <div class="col-md-2" v-if="property.l_payment == 'EFT'">
                  <div class="mb-md-0">
                    <label for="contact-info-location-input" class="form-label"> Transit</label>
                    <input type="text" class="form-control" v-model="property.l_transfer"
                      placeholder="Enter Bank Transit Number" />
                  </div>
                </div>
                <div class="col-md-2" v-if="property.l_payment == 'EFT'">
                  <div class="mb-md-0">
                    <label for="contact-info-location-input" class="form-label"> Account</label>
                    <input type="text" class="form-control" v-model="property.l_account_number"
                      placeholder="Enter Bank Account" />
                  </div>
                </div>




              </div>
              <!-- end row-->


              <!-- Summary Block  -->
              <div class="row pt-3">
                <label class="form-label">Summary Info</label>
              </div>
              <hr class="mt-1">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">

                      <div class="row">
                        <label class="form-label">Basic Info</label>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="mb-3 d-flex justify-content-end align-items-right">
                            <label class="form-label text-secondary">Property Manager</label>
                          </div>
                        </div>
                        <div class="col-md-8"><label class="form-label">{{ property.agent_name }}</label></div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="mb-3 d-flex justify-content-end align-items-right">
                            <label class="form-label text-secondary">RTA Code</label>
                          </div>
                        </div>
                        <div class="col-md-8"><label class="form-label">{{ rta_code }}</label></div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="mb-3 d-flex justify-content-end align-items-right">
                            <label class="form-label text-secondary">Address</label>
                          </div>
                        </div>
                        <div class="col-md-8"><label class="form-label">{{ `${addr_apt} ${addr_address} ${addr_city}
                            ${addr_province} ${addr_postal_code}` }}</label></div>
                      </div>


                    </div>
                    <!-- end col-->

                    <div class="col-md-6">

                      <div class="row">
                        <label class="form-label">Term Info</label>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="mb-3 d-flex justify-content-end align-items-right">
                            <label class="form-label text-secondary">Total Rent</label>
                          </div>
                        </div>
                        <div class="col-md-8"><label class="form-label">{{ Number(property.rent).toLocaleString()
                        }}</label>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="mb-3 d-flex justify-content-end align-items-right">
                            <label class="form-label text-secondary">Start Date</label>
                          </div>
                        </div>
                        <div class="col-md-8"><label class="form-label">{{ property.start_date }}</label></div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="mb-3 d-flex justify-content-end align-items-right">
                            <label class="form-label text-secondary">Commision</label>
                          </div>
                        </div>
                        <div class="col-md-8"><label class="form-label">{{ `${comm_type_rate == true ? (property.monthly_manager_commission+'%') : '$'+Number(property.monthly_manager_commission).toLocaleString()}`}}</label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <hr class="mt-1">
                  <div class="row">

                    <div class="col-md-6">

                      <div class="row">
                        <label class="form-label">Tenant Info</label>
                      </div>
                      <div class="row" v-for="(tenant, idx) in tenants" :key="'tenant_' + idx">

                        <div class="row">
                          <div class="col-md-2 gap-2 d-flex"><label class="form-label">●</label><label
                              class="form-label">Tenant{{ idx + 1 }}</label></div>
                          <div class="col-md-2">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label
                        text-secondary">Name</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ tenant.name }}</label></div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label
                        text-secondary"> Rent</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ Number(tenant.rent).toLocaleString()
                          }}</label>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label
                        text-secondary"> Pay Type</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ tenant.payment_type }}</label></div>
                        </div>

                        <div class="row" v-if="tenant.payment_type == 'PAD'">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary">Bank Info</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ `${tenant.institution} ${tenant.transit}
                              ${tenant.account}` }}</label></div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label
                        text-secondary"> Phone</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ tenant.phone }}</label></div>
                        </div>


                      </div>
                    </div>


                    <!-- end col-->
                    <div class="col-md-6">

                      <div class="row">
                        <label class="form-label">Landlord Info</label>
                      </div>
                      <div class="row" v-for="(landlord, idx) in landlords" :key="'landlord_' + idx">

                        <div class="row">
                          <div class="col-md-2 gap-2 d-flex"><label class="form-label">●</label><label
                              class="form-label">Landlord{{ idx + 1 }}</label></div>
                          <div class="col-md-2">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary">Name</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ landlord.name }}</label></div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary"> Owner ship</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ landlord.owner_ship }}%</label></div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary"> NR</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ landlord.landlord_is_non_resident
                            == '0' ? 'YES' : 'NO' }}</label></div>
                        </div>


                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary"> Type</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ landlord.payment_type }}</label></div>
                        </div>

                        <div class="row" v-if="landlord.payment_type == 'EFT'">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary">Bank Info</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ `${landlord.institution}
                              ${landlord.transit} ${landlord.account}` }}</label></div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary"> Address</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ landlord.landlord_address }}</label></div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary"> Info</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ landlord.email }}</label><label
                              class="form-label">{{ `${landlord.email2?'/'+landlord.email2: ''}` }}</label> <label class="form-label">{{ `${landlord.email3?'/'+landlord.email3: ''}` }}</label> </div>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->
                    <hr class="mt-1">
                    <div class="row">
                      <div class="col-md-6">

                        <div class="row">
                          <label class="form-label">BTO Info</label>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary">BTO</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ property.owner_name }}</label></div>
                        </div>

                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary">BTO Type</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ property.l_payment }}</label></div>
                        </div>

                        <div class="row" v-if="property.l_payment == 'EFT'">
                          <div class="col-md-4">
                            <div class="mb-3 d-flex justify-content-end align-items-right">
                              <label class="form-label text-secondary">Bank Info</label>
                            </div>
                          </div>
                          <div class="col-md-8"><label class="form-label">{{ `${property.l_institution}
                              ${property.l_transfer} ${property.l_account_number}` }}</label></div>
                        </div>

                      </div>
                      <!-- end col-->
                    </div>
                    <!-- end row -->


                  </div>
                  <!-- end Row summary-->
                </div>
                <!-- end card body-->
              </div>
              <!-- end card-->



              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-start d-flex align-items-left">
                    <button type="submit" class="btn btn-primary w-sm">
                      Setup RTA
                    </button>

                    <b-button variant="soft-light" type="button" @click="$router.push({ name: 'pm-property-list' })">
                      Cancel </b-button>

                  </div>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
